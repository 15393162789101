import moduleActivity from './moduleActivity'

export default {
  created () {
    if (!moduleActivity.isRegistered) {
      this.$store.registerModule('activity', moduleActivity)
      moduleActivity.isRegistered = true
    }
  }
}
