<template>
  <div id="activity-list">
    <div class="vx-card p-6">
      <vs-table class="w-100 my-4" stripe sst max-items="3" :data="activityList" @sort="onTableSort">
        <template slot="header">
          <!-- ITEMS PER PAGE -->
          <div class="flex-grow my-3">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ $t('activity.list.row_by_page_switcher', { rowStart, rowEnd, totalRow: totalItems }) }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item v-for="pageSize in pageSizeList" @click="paginationPageSize=pageSize" :key="pageSize">
                  <span>{{ pageSize }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" :placeholder="$t('activity.list.search.placeholder')" />
        </template>
        <template slot="thead">
          <vs-th sort-key="reportedAt">
            {{ $t('activity.list.table.header.reportedAt') }}
            <div v-if="false" class="filter w-full" @click.stop>
              <datepicker class="w-80" @input="filterDate" :language="langFr" v-model="filterValue.date"
                          :fullMonthName="true" minimumView="month" initialView="month" format="MMMM yyyy" name="date" />
              <vs-button v-if="filterValue.date" color="primary" type="border" icon="replay" @click="resetDate"></vs-button>
            </div>
          </vs-th>
          <vs-th sort-key="message">
            {{ $t('activity.list.table.header.message') }}
            <div v-if="false" class="filter w-full" @click.stop>
              <vs-select class="ml-auto my-2 cursor-pointer w-full" v-model="filterValue.type" @input="filterType" autocomplete>
                <vs-select-item key="" value="" :text="activityTypeText('all')" />
                <vs-select-item :key="state" :value="state" :text="activityTypeText(state)" v-for="state of activityTypeList"/>
              </vs-select>
            </div>
          </vs-th>
          <vs-th sort-key="reportedBy">
            {{ $t('activity.list.table.header.reportedBy') }}
            <div v-if="false" class="filter w-full" @click.stop>
              <vs-input v-model="filterValue.username" @input="filterUser"/>
            </div>
          </vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].reportedAt">
              {{ dateFormatter(data[indextr].reportedAt) }}
            </vs-td>
            <vs-td v-if="data[indextr].payload" :data="data[indextr]">
              <vs-collapse accordion>
                <vs-collapse-item>
                  <div slot="header">
                    {{ data[indextr].message }}
                  </div>
                  <p class="py-2">
                    <span class="font-semibold">{{ $t('activity.list.payload.translation_old') }}</span>
                    : {{ data[indextr].payload.translation_old }}</p>
                  <p class="py-2 text-primary">
                    <span class="font-semibold">{{ $t('activity.list.payload.translation_new') }}</span>
                    : {{ data[indextr].payload.translation_new }}</p>
                  </vs-collapse-item>
                </vs-collapse>
            </vs-td>
            <vs-td v-else :data="data[indextr].message">
              {{ data[indextr].message }}
            </vs-td>
            <vs-td v-if="data[indextr].reportedBy" :data="data[indextr].reportedBy">
              {{ data[indextr].reportedBy.name }} ({{ data[indextr].reportedBy.username }})
            </vs-td>
            <vs-td v-else>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/src/locale'
import {DateTime} from 'luxon'

//store
import moduleActivityMixin from '@/store/activity/moduleActivityMixin'


export default {
  components: {Datepicker},
  mixins: [moduleActivityMixin],
  data () {
    return {
      currentPage: 1,
      paginationPageSize: 10,
      pageSizeList: [10, 30, 50, 100],
      maxPageNumbers: 7,
      searchQuery: '',
      sortItem: {
        order: {
          ['reportedAt']: 'desc'
        }
      },
      filterItem: {},
      filterValue: {
        type: ''
      },
      searchItem: {},
      langFr: fr,
      activityTypeList: {}
    }
  },
  computed: {
    activityList () {
      return this.$store.state.activity.activities
    },
    totalItems () {
      return this.$store.state.activity.totalItems
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.paginationPageSize)
    },
    rowStart () {
      return (this.currentPage * this.paginationPageSize) - (this.paginationPageSize - 1)
    },
    rowEnd () {
      if (this.activityList.length - (this.currentPage * this.paginationPageSize) > 0) {
        return this.currentPage * this.paginationPageSize
      }

      return this.activityList.length
    }
  },
  watch: {
    '$store.state.windowWidth' (val) {
      if (val <= 576) {
        this.maxPageNumbers = 4
      }
    },
    'currentPage' () {
      this.fetchActivities()
    },
    'paginationPageSize' () {
      if (this.rowStart > this.totalItems) {
        this.currentPage = Math.ceil(this.totalItems / this.paginationPageSize)
        return
      }
      this.fetchActivities()
    },
    'filterItem' () {
      this.fetchActivities()
    }
  },
  methods: {
    updateSearchQuery () {
      if (this.searchQuery === '') {
        this.searchItem = {}
        this.fetchActivities()
        return
      }

      this.searchItem = {
        'resourceId[ior]': this.searchQuery,
        'resourceType[ior]': this.searchQuery
      }

      this.fetchActivities()
    },
    fetchActivities () {
      const payload = {
        page: this.currentPage,
        itemsPerPage: this.paginationPageSize,
        ...this.sortItem,
        ...this.searchItem,
        ...this.filterItem
      }
      this.$store.dispatch('activity/fetchActivities', payload)
        .catch(err => { console.error(err) }) // eslint-disable-line no-console
    },
    onTableSort (field, order) {
      this.sortItem = {}
      if (order) {
        this.sortItem = {
          order: {
            [field]: order
          }
        }
      }
      this.fetchActivities()
    },
    dateFormatter (date) {
      const options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}
      const dtf = new Intl.DateTimeFormat(['fr', 'en'], options)
      return dtf.format(new Date(date))
    },
    filterDate (date) {
      date = DateTime.fromJSDate(date).setZone('UTC').set({ hour: 0, minute: 0, second: 0 })

      this.filterItem = {
        ...this.filterItem,
        date: {
          'strictly_after': date.minus({ days: 1}).toISODate(),
          'strictly_before': date.plus({ days: 1}).toISODate()
        }
      }
    },
    resetDate () {
      this.$delete(this.filterItem, 'date')
      delete this.filterValue.date
    },
    filterType () {
      if (typeof this.filterItem.resourceType !== 'undefined' && this.filterValue.resourceType === '') {
        this.$delete(this.filterItem, 'resourceType')
        return
      }

      this.filterItem = {
        ...this.filterItem,
        state: {
          'exact': this.filterValue.type
        }
      }
    },
    filterUser () {
      if (typeof this.filterItem.resourceType !== 'undefined' && this.filterValue.resourceType === '') {
        this.$delete(this.filterItem, 'resourceType')
        return
      }

      this.filterItem = {
        ...this.filterItem,
        state: {
          'exact': this.filterValue.type
        }
      }
    },

    activityTypeText (name) {
      return this.$t(`activity.activity_type.list.${name}`)
    }
  },
  mounted () {
    this.fetchActivities()
    this.activityTypeList = JSON.parse(process.env.VUE_APP_AUDIT_TYPE || '["pricing"]')
  }
}
</script>

<style lang="scss">
th .vs-table-text {
  flex-direction: column;
}

.filter .vdp-datepicker .vdp-datepicker__calendar{
  z-index: 1000;
}
.con-tablex.vs-table--content{
  overflow: visible;
}
.vs-con-tbody.vs-table--tbody {
  overflow: visible;
}
.vs-collapse {
  padding: 0;
  .vs-collapse-item--header {
    font-size: 1rem;
    padding: 0;
  }
}
</style>
