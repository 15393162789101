import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    activities: [],
    totalItems: 0
  },
  mutations: {
    SET_ACTIVITIES (state, activitiesData) {
      state.activities = activitiesData['hydra:member']
      state.totalItems = activitiesData['hydra:totalItems']
    }
  },
  actions: {
    fetchActivities ({ commit, rootGetters }, payload) {
      if (rootGetters.hasCancelToken('fetchActivities')) {
        rootGetters.cancelToken('fetchActivities').cancel()
      }

      commit('SET_CANCEL_TOKEN', 'fetchActivities', {root: true})

      return new Promise((resolve, reject) => {
        let url = '/audits'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios.get(url, {
          cancelToken: rootGetters.cancelToken('fetchActivities').token
        }).then((response) => {
          commit('SET_ACTIVITIES', response.data)
          resolve(response)
        })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {}
}
